import { isValidPhoneNumber } from 'libphonenumber-js';
import { stdnum } from 'stdnum';
import { InvalidFormat } from 'stdnum/lib/cjs/exceptions';
import { ValidateReturn } from 'stdnum/lib/cjs/types';
import { PostalCodeLocale } from 'validator';
import isBIC from 'validator/es/lib/isBIC';
import isEmail from 'validator/es/lib/isEmail';
import isIBAN from 'validator/es/lib/isIBAN';
import isPostalCode from 'validator/es/lib/isPostalCode';
import isUrl from 'validator/es/lib/isURL';
import isUUID from 'validator/es/lib/isUUID';

import { CountryCode, countryCodeMapping } from '@bootstrap/constants/countries';

const validateVatNumber: Record<CountryCode, (value: string) => ValidateReturn> = {
  NL: (value: string) => stdnum.NL.btw.validate(value),
  DE: (value: string) => stdnum.DE.vat.validate(value),
  BE: (value: string) => stdnum.BE.vat.validate(value),
  UK: (value: string) => stdnum.GB.vat.validate(value),
  GB: (value: string) => stdnum.GB.vat.validate(value),
  FR: (value: string) => stdnum.FR.tva.validate(value),
  AT: (value: string) => stdnum.AT.uid.validate(value),
  BG: (value: string) => stdnum.BG.vat.validate(value),
  DK: (value: string) => stdnum.DK.vat.validate(value),
  IT: (value: string) => stdnum.IT.iva.validate(value),
  LV: (value: string) => stdnum.LV.pvn.validate(value),
  LI: (value: string) => stdnum.LI.uid.validate(value),
  NO: (value: string) => stdnum.NO.mva.validate(value),
  PT: (value: string) => stdnum.PT.nif.validate(value),
  PL: (value: string) => stdnum.PL.nip.validate(value),
  RO: (value: string) => stdnum.RO.cui.validate(value),
  ES: (value: string) => stdnum.ES.cif.validate(value),
  SE: (value: string) => stdnum.SE.vat.validate(value),
  CH: (value: string) => stdnum.CH.uid.validate(value),
  /**
   * PIN and VAT numbers are the same in Ukraine
   * we should use stdnum.UA.rntrc for both
   */
  UA: (value: string) => stdnum.UA.rntrc.validate(value),
  GE: (value: string) => isGeorgiaValidVatNumber(value),
};

const validatePinNumber: Record<CountryCode, (value: string) => ValidateReturn> = {
  NL: (value: string) => stdnum.NL.bsn.validate(value),
  DE: (value: string) => stdnum.NL.svnr.validate(value),
  BE: (value: string) => stdnum.BE.nn.validate(value),
  UK: (value: string) => stdnum.GB.nino.validate(value),
  GB: (value: string) => stdnum.GB.nino.validate(value),
  FR: (value: string) => stdnum.FR.nir.validate(value),
  AT: (value: string) => stdnum.AT.svnr.validate(value),
  BG: (value: string) => stdnum.BG.egn.validate(value),
  DK: (value: string) => stdnum.DK.cpr.validate(value),
  IT: (value: string) => stdnum.IT.codicefiscale.validate(value),
  LV: (value: string) => stdnum.LV.pn.validate(value),
  LI: (value: string) => stdnum.LI.ahv.validate(value),
  NO: (value: string) => stdnum.NO.fn.validate(value),
  PT: (value: string) => stdnum.PT.niss.validate(value),
  PL: (value: string) => stdnum.PL.pesel.validate(value),
  RO: (value: string) => stdnum.RO.cnp.validate(value),
  ES: (value: string) => stdnum.ES.dni.validate(value),
  SE: (value: string) => stdnum.SE.personnummer.validate(value),
  CH: (value: string) => stdnum.CH.avsnr.validate(value),
  /**
   * PIN and VAT numbers are the same in Ukraine
   * we should use stdnum.UA.rntrc for both
   */
  UA: (value: string) => stdnum.UA.rntrc.validate(value),
  GE: (value: string) => isGeorgiaValidPinNumber(value),
};

export class Validator {
  public static isBIC = isBIC;
  public static isUUID = isUUID;
  public static isPostalCode = (value: string, countryCode: CountryCode): boolean => {
    if (countryCode === countryCodeMapping.GE) {
      return Validator.isGeorgiaPostalCode(value);
    }
    return isPostalCode(value, countryCode as PostalCodeLocale);
  };
  public static isEmail = isEmail;
  public static isIBAN = isIBAN;
  public static isUrl = isUrl;

  public static isVAT = (value: string, countryCode: CountryCode): boolean => {
    const validate = validateVatNumber[countryCode];
    return validate ? validate(value).isValid : true;
  };

  public static isPinNumber = (value: string, countryCode: CountryCode): boolean => {
    const validate = validatePinNumber[countryCode];
    return validate ? validate(value).isValid : true;
  };

  public static isPhoneNumber = (value: string): boolean => {
    return isValidPhoneNumber(value);
  };

  /**
   * Custom postal code validator for Georgia (GE).
   * Georgian postal codes are always 4 digits.
   */
  private static isGeorgiaPostalCode = (value: string): boolean => {
    return /^\d{4}$/.test(value);
  };
}

/**
 * VAT are the same in Ukraine
 * GE VAT numbers contains 9 digits only
 *
 * Custom validator due to `stdnum` is not supporting GE.
 */
export const isGeorgiaValidVatNumber = (value: string): ValidateReturn => {
  if (!value || !/^(\d{9})$/.test(value)) {
    return {
      isValid: false,
      error: new InvalidFormat(),
    };
  }

  return {
    isValid: true,
    compact: value,
    /**
     * It's hard to define either it's company or individual person as there are no strict rules.
     * Exists validators are deprecated or incorrect.
     * I didn't find this fields usage, probably it doesn't matter for us.
     */
    isIndividual: false,
    isCompany: false,
  };
};

/**
 * PIN are the same in Ukraine
 * GE PIN number contains 11 digits only
 *
 * Custom validator due to `stdnum` is not supporting GE.
 */
export const isGeorgiaValidPinNumber = (value: string): ValidateReturn => {
  if (!value || !/^(\d{11})$/.test(value)) {
    return {
      isValid: false,
      error: new InvalidFormat(),
    };
  }

  return {
    isValid: true,
    compact: value,
    /**
     * It's hard to define either it's company or individual person as there are no strict rules.
     * Exists validators are deprecated or incorrect.
     * I didn't find this fields usage, probably it doesn't matter for us.
     */
    isIndividual: false,
    isCompany: false,
  };
};

/**
 * Validates email array
 * @param emails - emails array
 * @param error - error in case of any email is invalid
 */
export const validateEmails = (emails: string[], error: string): string | undefined => {
  return emails.some((email) => !Validator.isEmail(email)) ? error : undefined;
};

/**
 * Validates IBAN last 4 digits
 * @param value - IBAN string that validates
 * @param last4 - IBAN last 4 digits to validate
 */
export const isIbanLast4Valid = (value: string, last4: number): boolean => {
  return !!value.match(new RegExp(`${last4}$`))
}
